import React, { useState, useRef } from "react";
import { Paper, Box, Typography, Divider, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Importer, ImporterField } from "react-csv-importer";
import "./Card.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import template from "../../Assets/template2.csv";
import { ShopifyModal } from "../Modal/Modal";

export const Card = ({ Heading, Title, To, ButtonText, Type }) => {
  const [data, setData] = useState([]);
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    Papa.parse(event.target.files[0], {
      skipEmptyLines: true,
      complete: function (results) {
        var arr = results.data;
        var finalArr = [];
        arr[0].id = 1;
        for (var i = 1; i < arr.length; i++) {
          var str = "";
          var flag = 0;
          var price = "",
            name = "";
          for (var j = 4; j < arr[i].length; j++) {
            name = arr[i][0];
            price = arr[i][2];
            if (arr[i][j]) {
              str += arr[i][j];
              flag++;
              if (flag / 2 < 2) {
                if (arr[i][j + 2] == "") {
                  str += ":" + arr[i][j + 1];
                } else {
                  str += ":" + arr[i][j + 1] + "|";
                }

                j++;
              }
            }
          }
          let temp = {
            Attributes: str,
            Name: name,
            Price: price,
            Description: "",
            id: i,
          };
          finalArr.push(temp);
        }
        navigate("/payment", { state: finalArr });
      },
    });
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleOpenModal = () => {
    shopifyModalRef.current.handleOpen();
  };

  const shopifyModalRef = useRef();

  return (
    <>
      <ShopifyModal ref={shopifyModalRef} />
      <Paper className="Card Flex">
        <Box className="UpperSection">
          <Typography className="headingText">{Heading}</Typography>
          <a href={template} download className="TitleText">
            {Title}
          </a>
        </Box>
        <Divider sx={{ background: "#979797", width: "100%" }} />
        <Box className="LowerSection">
          <Button
            className="LinkContent"
            to={To}
            onClick={() => (Type == "Import" ? null : shopifyModalRef)}
          >
            <Typography
              className="LinkText"
              onClick={Type == "Import" ? handleClick : handleOpenModal}
            >
              {ButtonText}
            </Typography>
            {Type == "Import" ? (
              <input
                onChange={handleFileChange}
                type="File"
                ref={hiddenFileInput}
                style={{ display: "none" }}
              />
            ) : null}
          </Button>
        </Box>
      </Paper>
    </>
  );
};
