import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import CheckoutForm from "../Components/Checkform/CheckForm";
import { createpaymentIntent } from "./../services/paymentApi";
import { Typography } from "@mui/material";
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

export default function Payment() {
  const [clientSecret, setClientSecret] = useState("");
  const [pricePerProduct] = useState(0.25);
  const { state } = useLocation();
  useEffect(() => {
    if (state.length != 0) {
      createpaymentIntent(state)
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret))
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App1">
      <Typography
        style={{
          margin: "0px",
          padding: "0px",
          marginBottom: "10px",
          fontWeight: "bold",
        }}
      >
        Price per product:{" "}
        <span style={{ fontWeight: "normal" }}>£ {pricePerProduct}</span>
      </Typography>
      <Typography
        style={{
          margin: "0px",
          padding: "0px",
          marginBottom: "10px",
          fontWeight: "bold",
        }}
      >
        Total products:{" "}
        <span style={{ fontWeight: "normal" }}>{state.length}</span>
      </Typography>
      <Typography
        style={{
          margin: "0px",
          padding: "0px",
          marginBottom: "10px",
          fontWeight: "bold",
        }}
      >
        Total price:{" "}
        <span style={{ fontWeight: "normal" }}>
          £ {state.length * pricePerProduct}
        </span>
      </Typography>
      {state.length == 0
        ? alert("There should be atleast 1 product")
        : clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm state={state} />
            </Elements>
          )}
      {}
    </div>
  );
}
