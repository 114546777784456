import { signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
export async function loginuser(auth, email, password) {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return await response;
  } catch (error) {
    return "User not logged in";
  }
}
export async function createUser(auth, email, password) {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return await response;
  } catch (error) {
    return "User not created";
  }
}
