import React from "react";

import { Navigate, useLocation } from "react-router-dom";

const PaymentRoute = ({ children }) => {
  const payment = localStorage.getItem("Payment");

  let location = useLocation().pathname;

  if (!payment) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default PaymentRoute;
