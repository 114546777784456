import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import ProtectedRoute from "../ProtectedRoutes/ProtectedRoutes";

import { NavBar } from "../Components/NavBar/NavBar";
import { Home } from "../Screens/Home";
import { Product } from "../Screens/Product";
import { Login } from "../Screens/Login";
import { Signup } from "../Screens/Signup";
import UnProtectedRoute from "../UnProtectedRoutes/UnProtectedRoutes";
import { File } from "../Screens/File";
import CheckoutForm from "../Components/Checkform/CheckForm";
import Payment from "../Screens/Payment";
import PaymentRoute from "../PaymentProtect/PaymentProtect";
import ShowStripe from "../ShowStripe/ShowStripe";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <NavBar />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products/:slug",
        element: <Product />,
      },
      {
        path: "/file",
        element: (
          <PaymentRoute>
            <File />
          </PaymentRoute>
        ),
      },
      {
        path: "/payment",
        element: <Payment />,
      },
    ],
  },
  {
    path: "/Login",
    element: (
      <UnProtectedRoute>
        {" "}
        <Login />{" "}
      </UnProtectedRoute>
    ),
  },
  {
    path: "/signup",
    element: (
      <UnProtectedRoute>
        {" "}
        <Signup />
      </UnProtectedRoute>
    ),
  },
]);
