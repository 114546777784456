import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useLocation } from "react-router-dom";
import "./Table.css";

import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

export const Table = ({ row, columns, Type }) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const handleClick = (params) => {
    navigate(`/Products/${params.row.job_id}`);
  };

  return (
    <DataGrid
      rows={row}
      columns={columns}
      autoHeight={true}
      pageSizeOptions={[20]}
      getRowHeight={() => "auto"}
      onRowClick={path == "/" ? handleClick : null}
      disableRowSelectionOnClick
      disableExport
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 20,
          },
        },
      }}
      // initialState={{
      //   ...data.initialState,
      //   pagination: { paginationModel: { pageSize: 5 } },
      // }}

      // slots={{
      //   pagination: CustomPagination,
      // }}
      sx={{ width: "100%", cursor: "pointer" }}
    />
  );
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      page={page + 1}
      count={pageCount}
      sx={{
        "& .Mui-selected": {
          backgroundColor: "transparent !important",
          color: "#ec492f",
        },
      }}
      renderItem={(props2) => (
        <PaginationItem className="paginationText" {...props2} />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}
