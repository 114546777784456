import axios from "axios";
export async function modalApi(data) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}getShopifyProducts`,
      { data }
    );
    return await response;
  } catch (error) {
    return "User not logged in";
  }
}
