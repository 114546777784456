import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../Components/Tables/Table";
import { Typography, Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { toast } from "react-toastify";
import { useGridApiContext } from "@mui/x-data-grid";
import { openAiDescription, addProduct } from "./../services/openAi";

function CustomEditComponent(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  return (
    <textarea
      className="textAreaEdit"
      type="text"
      row="10"
      value={value}
      onChange={handleValueChange}
    />
  );
}

export const File = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [productdata, setData] = useState(state);
  const [isGenerate, setIsGenerate] = useState(false);
  const apiKey = process.env.REACT_APP_GENERATE_API_KEY;
  const apiUrl = `${process.env.REACT_APP_GENERATE_API_URL}/completions`;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const generateResponse = async () => {
    localStorage.removeItem("Payment");
    setIsLoading(true);
    state.map(async (item, index) => {
      const data = await openAiDescription(apiUrl, apiKey, item);
      setIsLoading(false);
      const generatedText = data.choices[0].text;
      let genText = generatedText.split("\n");
      addData(index, generatedText);

      setIsGenerate(true);
    });

    const addData = (index, data) => {
      let temp = [...productdata];
      temp[index].Description = data;
      setData(temp);
    };
  };
  const columns = [
    {
      field: "Name",
      headerAlign: "left",
      headerName: "Product Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.Name ? params.row.Name : params.row.title}
          </Typography>
        );
      },
    },

    {
      field: "Attributes",
      headerName: "Attributes",
      headerAlign: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "flex", flexDirection: "column", textAlign: "left" }}
          >
            {params.row.Attributes
              ? params.row.Attributes?.split("|").map((item, index) => {
                  return <Typography key={index}>{item}</Typography>;
                })
              : params.row.options.map((item, index) => {
                  return (
                    <Typography key={index}>
                      {item.name}: {item.values.join(",")}
                    </Typography>
                  );
                })}
          </Box>
        );
      },
    },

    {
      field: "Description",
      headerName: "Description",
      headerAlign: "left",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return <Box sx={{ textAlign: "left" }}>{params.row.Description}</Box>;
      },
      renderEditCell: (params) => <CustomEditComponent {...params} />,
    },
  ];

  const save = async () => {
    setIsLoading1(true);
    let data = [];

    if (!productdata[0].Name) {
      data = convertData();
    }

    try {
      const docRef = await addDoc(collection(db, "jobs"), {
        userid: auth.currentUser.uid,
        products: productdata[0].Name ? productdata : data,
      });
      localStorage.getItem("store_name");
      localStorage.getItem("api_key");
      localStorage.getItem("password");

      productdata.map((item) => {
        const data = {
          productdata: item,
          apiKey: localStorage.getItem("api_key"),
          admin: localStorage.getItem("password"),
          shopName: localStorage.getItem("store_name"),
        };
        addProduct(data)
          .then((res) => {
            setIsLoading1(false);
          })
          .catch((err) => {
            setIsLoading1(false);
            toast("Error adding document");
          });
      });
      toast("Products Saved");
      navigate(`/Products/${docRef.id}`);
      console.log("Document written with ID2: ", docRef.id);
    } catch (e) {
      alert("Error adding document");
      console.error("Error adding document: ", e);
    }
  };
  const convertData = () => {
    let temp = [];
    productdata.map((item) => {
      temp.push({
        Name: item.title,
        Price: item.variants[0].price,
        Attributes: `${item.options.map((ele) => {
          return `${ele.name}: ${ele.values.join(",")}` + "|";
        })}`,
        Description: item.Description,
        id: item.id,
      });
    });
    return temp;
  };

  return (
    <Box className="ContainserDetail2">
      {isGenerate && (
        <Button
          style={{
            background: "black",
            color: "white",
            fontSize: "14px",
            fontWeight: "700",
          }}
          onClick={() => save()}
        >
          Save Products
        </Button>
      )}
      {isLoading1 ? <CircularProgress /> : null}
      <Table row={productdata} columns={columns} Type={"File"} />
      {isLoading ? <CircularProgress /> : null}
      <Button
        style={{
          background: "black",
          color: "white",
          fontSize: "14px",
          fontWeight: "700",
        }}
        onClick={() => generateResponse()}
      >
        Generate Product Description
      </Button>
    </Box>
  );
};
