import React from 'react';

import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("auth");

  let location = useLocation().pathname;

  if (!user) {
    return <Navigate to="/Login" state={{ from: location }} replace />;
  }
  return children;

};

export default ProtectedRoute;