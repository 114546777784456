import React, { useState, useEffect } from "react";
import { Table } from "../Components/Tables/Table";
import { Card } from "../Components/Cards/Card";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Typography,
  Box,
  Button,
  InputBase,
  FormControl,
  Select,
  MenuItem,
  Menu,
  Paper,
  IconButton,
} from "@mui/material";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { db, auth } from "../firebase";
import SearchIcon from "@mui/icons-material/Search";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import cross from "../Assets/cross.svg";

export const Product = () => {
  const { slug } = useParams();
  const [prod, setProd] = useState([]);
  const [loadAgain, setLoadAgain] = useState(false);
  const [row, setRow] = useState([]);
  const [query1, setQuery1] = useState("");
  const [filterProd, setFilterProd] = useState([]);
  const [rowDesc, setRowDesc] = useState("");
  const [rowData, setRowData] = useState("");
  const style = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "16px",
    width: "40%",
    height: "361px",
    p: 4,
  };

  async function getData() {
    console.log(auth.currentUser);
    // if (auth.currentUser) {
    const q = query(
      collection(db, "jobs"),
      where("userid", "==", auth.currentUser.uid)
    );
    const querySnapshot = await getDocs(q);
    let datas = [];
    let ind = 0;

    querySnapshot.forEach((doc) => {
      let docData = doc.data();

      ind = ind + 1;
      docData.id = ind;
      docData.productlength = docData.products.length;
      docData.job_id = doc.id;
      datas.push(docData);
    });
    let jobIdData = datas.find((product) => {
      return product.job_id == slug;
    });
    setProd(jobIdData);
    setRow(jobIdData.products);
    setFilterProd(jobIdData.products);
    // }
  }
  useEffect(() => {
    if (!row.length) {
      if (auth.currentUser) {
        getData();
      } else {
        setLoadAgain(!loadAgain);
      }
    }
  }, [query1, loadAgain]);

  const searchFilter = (text) => {
    if (text) {
      const newData = row.filter((item, index) => {
        const itemMake = item.Name ? item.Name.toUpperCase() : "".toUpperCase();
        const itemMake1 = item.Description
          ? item.Description.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        if (
          itemMake.indexOf(itemMake) > -1 ||
          itemMake1.indexOf(itemMake1) > -1
        ) {
          return (
            itemMake.indexOf(textData) > -1 || itemMake1.indexOf(textData) > -1
          );
        }
      });

      setFilterProd(newData);
      setQuery1(text);
    } else {
      setFilterProd(row);
      setQuery1(text);
    }
  };
  useEffect(() => {
    setFilterProd(row);
  }, [row]);

  const [viewDetail, showDetail] = useState([]);
  const showMore = (id) => {
    let temp = [...viewDetail];
    temp.push(id);
    showDetail(temp);
  };
  const showLess = (id) => {
    let temp = [...viewDetail];
    const i = temp.findIndex((item) => item.id == id);
    temp.splice(i, 1);
    showDetail(temp);
  };

  const columns = [
    { field: "Name", headerName: "Product name", flex: 1, minHeight: "61px" },
    {
      field: "Description",
      headerName: "Description",
      headerAlign: "left",
      flex: 2,
      editable: true,
      minHeight: "61px",
      renderCell: (params) => (
        <div
          style={{
            display: viewDetail.includes(params.row.id) ? "block" : "flex",
          }}
        >
          <Typography sx={{ textAlign: "left" }}>
            {params.row.Description.substring(
              0,
              !viewDetail.includes(params.row.id)
                ? 52
                : params.row.Description.length
            )}
          </Typography>
          {params.row.Description.length > 50 ? (
            !viewDetail.includes(params.row.id) ? (
              <Typography
                onClick={() => showMore(params.row.id)}
                sx={{ cursor: "pointer" }}
              >
                ...
                <span style={{ textDecoration: "underline" }}>Show More</span>
              </Typography>
            ) : (
              <Typography
                onClick={() => showLess(params.row.id)}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                Show Less
              </Typography>
            )
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      field: " ",
      headerName: "Status",
      flex: 1,
      minHeight: "61px",
      renderCell: (params) => (
        <Button
          onClick={async () => {
            setRowDesc(params.row.Description);
            setRowData(params);
            setOpen(true);
          }}
        >
          <Typography className="button">Edit</Typography>
        </Button>
      ),
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return (
    <Box className="ContainserDetail3">
      <ToastContainer />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              style={{
                padding: "0px",
                width: 12.1,
                height: 12.1,
                margin: "0px",
                minWidth: "18px",
              }}
              onClick={handleClose}
            >
              <img
                style={{
                  padding: "0px",
                  margin: "0px",
                  width: 12.1,
                  height: 12.1,
                }}
                alt="Logo"
                src={cross}
              />
            </Button>
          </div>
          <Typography
            id="modal-modal-title"
            sx={{
              marginTop: "25px",
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "35px",
              color: "#303133",
            }}
            variant="h6"
            component="h2"
          >
            Edit Product Description
          </Typography>
          <textarea
            type="text"
            row="10"
            value={rowDesc}
            className="textareadescription"
            onChange={(event) => {
              setRowDesc(event.target.value);
            }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Button
              onClick={async () => {
                rowData.row.Description = rowDesc;
                const productRef = doc(db, "jobs", slug);
                await updateDoc(productRef, {
                  products: row,
                });
                toast("Product description updated");
                setOpen(false);
              }}
              className="textAreaButton"
            >
              {" "}
              <Typography className="textAreaTypo">Save</Typography>
            </Button>
          </div>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CustomizedInputBase value={query1} setValue={searchFilter} />
        {row.length ? (
          <CSVLink
            filename={`productSpark-${slug}.csv`}
            className="downloadcsvButton textAreaTypo"
            data={row}
          >
            Download csv
          </CSVLink>
        ) : (
          <div></div>
        )}
      </Box>
      {row.length ? <Table row={filterProd} columns={columns} /> : <div></div>}
    </Box>
  );
};

export default function CustomizedInputBase({ value, setValue }) {
  return (
    <Paper
      className="form1"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 228,
        height: "34px",
        border: "1px solid #858C94",
        boxSizing: "border-box",
        borderRadius: "8px",
      }}
      elevation={0}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search..."
        className="plh-text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Paper>
  );
}
