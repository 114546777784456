export async function createpaymentIntent(state) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}create-payment-intent`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: state }),
      }
    );
    return await response;
  } catch (error) {
    return "payment intent not created";
  }
}
