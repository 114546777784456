import React, { useImperativeHandle, forwardRef, useState } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { modalApi } from "../../services/modalApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 477,
  bgcolor: "background.paper",
  height: "533px",
  boxShadow: 24,
  p: "10px 24px 10px 24px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
};

export const ShopifyModal = forwardRef(({ id }, ref) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = useState(false);
  const [apiKey, setApiKey] = useState(false);
  const [adminPassword, setAdminPassword] = useState(false);
  const [error, setError] = useState({
    name: "",
    apikey: "",
    adminPassword: "",
  });
  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
    handleClose() {
      handleClose();
    },
  }));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = (e) => {
    setName(false);
    setAdminPassword(false);
    setApiKey(false);
    setIsLoading(true);
    e.preventDefault();
    if (e.target[0].value && e.target[2].value && e.target[4].value) {
      let storeUrlSplit = e.target[0].value.split(".")[0];
      let storeUrlSplit1 = storeUrlSplit.split("/");
      let storeName = storeUrlSplit1[storeUrlSplit1.length - 1];

      localStorage.setItem("store_name", storeName);
      localStorage.setItem("api_key", e.target[2].value);
      localStorage.setItem("password", e.target[4].value);
      const data = {
        shopName: storeName,
        apiKey: e.target[2].value,
        admin: e.target[4].value,
      };
      modalApi(data)
        .then((res) => {
          if (res.data.hasOwnProperty("error")) {
            setIsLoading(false);
            let errorAlert = res.data.error.split(" ");
            let statusCode = errorAlert[errorAlert.length - 1];
            if (statusCode == "404") {
              alert("Store url is invalid");
            } else if (statusCode == "403") {
              alert("API key is invalid");
            } else if (statusCode == "401") {
              alert("API secret is invalid");
            } else {
              alert("Credentials are invalid");
            }
          } else {
            setIsLoading(false);
            res.data.data.products.map((product, index) => {
              res.data.data.products[index].Description =
                res.data.data.products[index].body_html;
            });
            navigate("/payment", { state: res.data.data.products });
          }
        })
        .catch((err) => {
          alert("Error when adding credentials");
        });
    } else if (!e.target[0].value || !e.target[2].value || !e.target[4].value) {
      if (!e.target[0].value) {
        setName(true);
      }
      if (!e.target[2].value) {
        setApiKey(true);
      }
      if (!e.target[4].value) {
        setAdminPassword(true);
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "scroll" }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
            onSubmit={handleSubmit}
          >
            <Box>
              <Typography>Please enter Shopify admin information</Typography>
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography
                style={{
                  textAlign: "left",
                  marginBottom: "5px",
                  color: "red",
                  fontSize: "11px",
                }}
              >
                {name ? "Please enter store url" : ""}
              </Typography>

              <Tooltip title="Add base store URL of shopify (xyz.myshopify.com)">
                <TextField
                  name="Name"
                  id="outlined-basic"
                  label="Store Url"
                  variant="outlined"
                  // value={name}
                  // onChange={(e) => setName(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Tooltip>
            </Box>

            <Box style={{ width: "100%" }}>
              <Typography
                style={{
                  textAlign: "left",
                  marginBottom: "5px",
                  color: "red",
                  fontSize: "11px",
                }}
              >
                {apiKey ? "Please enter API key" : ""}
              </Typography>

              <Tooltip title="API must have Product Read/Write Permissions">
                <TextField
                  name="ApiKey"
                  id="outlined-basic"
                  label="API Key"
                  variant="outlined"
                  // value={apiKey}
                  // onChange={(e) => setApiKey(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Tooltip>
            </Box>
            <Box style={{ width: "100%" }}>
              <Typography
                style={{
                  textAlign: "left",
                  marginBottom: "5px",
                  color: "red",
                  fontSize: "11px",
                }}
              >
                {adminPassword ? "Please enter API Access token" : ""}
              </Typography>

              <Tooltip title="Admin API Access token generated when shopify app was created.">
                <TextField
                  name="Password"
                  id="outlined-basic"
                  label="API Access token"
                  variant="outlined"
                  // value={adminPassword}
                  // onChange={(e) => setAdminPassword(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Tooltip>
            </Box>
            {isLoading ? <CircularProgress /> : null}
            <Button type="submit" className="modalsubmit">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
});
