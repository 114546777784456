import React, { useEffect, useState } from "react";
import { Table } from "../Components/Tables/Table";
import { Card } from "../Components/Cards/Card";
import { useLocation } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import * as firebase from "firebase/app";
import { db, auth } from "../firebase";
import { CSVLink, CSVDownload } from "react-csv";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  where,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Home = () => {
  const [tasks, setTasks] = useState([]);
  const [gData] = useState([]);
  const result = useLocation();
  async function getData() {
    if (auth.currentUser) {
      const q = query(
        collection(db, "jobs"),
        where("userid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      let datas = [];
      let ind = 0;
      querySnapshot.forEach((doc) => {
        let docData = doc.data();

        ind = ind + 1;
        docData.id = ind;
        docData.productlength = docData.products.length;
        docData.job_id = doc.id;
        datas.push(docData);
      });
      setRow(datas);
    }
  }
  let sta = "";
  useEffect(() => {
    if (!sta) {
      toast(result.state);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      sta = result.state;
    }
  }, []);
  useEffect(() => {
    if (!row.length) {
      const q = query(collection(db, "products"), orderBy("created", "desc"));
      onSnapshot(q, (querySnapshot) => {
        setTasks(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
      getData();
    }
  });

  const [row, setRow] = useState([]);
  const columns = [
    {
      field: "id",
      headerName: "Job id",
      flex: 1,
      render: (params) => {
        <NavLink
          className="navbar-item"
          activeClassName="is-active"
          to="/products"
        >
          {params}
        </NavLink>;
      },
    },

    {
      field: "productlength",
      headerName: "No. of Products",
      flex: 1,
      render: (params) => {
        <Typography>{params}</Typography>;
      },
    },
  ];

  return (
    <Box className="ContainserDetail2">
      <ToastContainer />
      <Box
        className="center"
        sx={{ display: "flex", gap: 7, flexWrap: "wrap" }}
      >
        <Card
          Type="Import"
          Title={"Click here to download the Csv template"}
          Heading={"Upload  a Csv"}
          to={`/products`}
          ButtonText={"UPLOAD CSV"}
        />
        <Card
          Title={"Login to your store to automatically sync data."}
          Heading={"Connect Shopify"}
          to={`/products`}
          ButtonText={"CONNECT STORE"}
        />
      </Box>

      <Table row={row} columns={columns} />
    </Box>
  );
};

const getColor = (status) => {
  switch (status) {
    case "Complete":
      return "#6CA430";
    case "Working":
      return "#CFAF05";
    case "Failed":
      return "#EC492F";
  }
};
