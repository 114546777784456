import axios from "axios";
export async function openAiDescription(apiUrl, apiKey, item) {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: "text-davinci-003",
        prompt: `Create product description for a ${item.Name} with ${
          item.Attributes
            ? item.Attributes.split("|").map((item) => {
                console.log("attribute item", item);
                return item;
              })
            : item.options.map((item) => {
                console.log("option item", item);
                return item.values.join(",") + " " + item.name;
              })
        }, and please provide exact, precise description`,

        max_tokens: 300,
        temperature: 0,
      }),
    });
    return await response.json();
  } catch (error) {
    return "Can't get descritpion";
  }
}
export async function addProduct(data) {
  try {
    const response = axios.post(
      `${process.env.REACT_APP_API_URL}updateProducts`,
      { data }
    );
    return await response;
  } catch (error) {
    return "User not logged in";
  }
}
