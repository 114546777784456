import React from 'react';

import { Navigate, useLocation } from "react-router-dom";

const UnProtectedRoute = ({ children }) => {
  const user = localStorage.getItem("auth");
  let location = useLocation().pathname;

  if (user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;

};

export default UnProtectedRoute;