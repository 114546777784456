import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { loginuser } from "./../services/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { async } from "@firebase/util";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import productsparklogo from "../Assets/ProductSparkLogo.svg";
export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    await loginuser(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("auth", user.accessToken);
        navigate("/", { state: "User logged in successfuly" });
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrorMessages(errorMessage);
      });
  };
  useEffect(() => {
    save();
  }, []);
  const save = async () => {
    const docData = {
      stringExample: "Hello world!",
      booleanExample: true,
      numberExample: 3.14159265,
      dateExample: Timestamp.fromDate(new Date("December 10, 1815")),
      arrayExample: [5, true, "hello"],
      nullExample: null,
      objectExample: {
        a: 5,
        b: {
          nested: "foo",
        },
      },
    };
    const data = await setDoc(doc(db, "data", "one"), docData);
  };
  const renderForm = (
    <div className="form">
      <div>
        <div className="input-container">
          <label>Email</label>
          <input
            type="text"
            className="loginField"
            name="uname"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-container">
          <label>Password</label>
          <input
            type="password"
            className="loginField"
            name="pass"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMessages ? <div className="error">{errorMessages}</div> : null}
        </div>
        <div className="button-container">
          <button
            onClick={handleSubmit}
            className="loginbtn"
            label="Login"
            value="Login"
          >
            Login{" "}
          </button>
        </div>
      </div>
    </div>
  );
  return (
    <div className="app">
      <ToastContainer />
      <img className="productsparklogo" alt="Logo" src={productsparklogo} />
      <div className="login-form width-100 height-100">
        <div className="title">Sign In</div>
        {renderForm}
      </div>
      <a href="/Signup" style={{ color: "black" }}>
        Click here for sign up{" "}
      </a>
    </div>
  );
};
