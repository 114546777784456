import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { auth } from "../firebase";
import { async } from "@firebase/util";
import { getAuth } from "firebase/auth";
import productsparklogo from "../Assets/ProductSparkLogo.svg";
import { createUser } from "./../services/auth";
export const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    await createUser(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("auth", user.accessToken);
        navigate("/", {
          state: "Account created and User logged in successfuly",
        });
      })
      .catch((error) => {
        const errorMessage = error.message;
        setErrorMessages(errorMessage);
      });
  };

  const renderForm = (
    <div className="form">
      <div>
        <div className="input-container">
          <label>Email</label>
          <input
            type="text"
            className="loginField"
            name="uname"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-container">
          <label>Password</label>
          <input
            type="password"
            className="loginField"
            name="pass"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMessages ? <div className="error">{errorMessages}</div> : null}
        </div>
        <div className="button-container">
          <button
            onClick={handleSubmit}
            className="loginbtn"
            label="Login"
            value="Login"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="app">
      <img className="productsparklogo" alt="Logo" src={productsparklogo} />
      <div className="login-form width-100 height-100">
        <div className="title">Sign Up</div>
        {renderForm}
      </div>
      <a href="/login" style={{ color: "black" }}>
        Click here for sign in{" "}
      </a>
    </div>
  );
};
